import React from 'react';

import { simpleFormatText } from './formatText';

import './cover.css';

export default function Cover(props) {
  if (!props.showCover) return false;

  return (
    <div className="cover">
      <div className="churchName">{simpleFormatText(props.churchName)}</div>
      <div className="serviceTitle">{simpleFormatText(props.serviceTitle)}</div>
      <div className="serviceSubTitle">
        {simpleFormatText(props.serviceSubTitle)}
      </div>
      <div className="dateTime">
        <div className="date">{props.date}</div>
        <div className="time">{props.time}</div>
      </div>
      <div className="strapline">{simpleFormatText(props.strapline)}</div>
    </div>
  );
}
