import React from 'react';
import InfoDialogue from './InfoDialogue';

import './tools.css';

export default function Tools(props) {
  const toggleEditing = e => {
    e.preventDefault();

    props.toggleEditing();
  };

  const preview = e => {
    e.preventDefault();

    props.preview();
  };

  const print = e => {
    e.preventDefault();

    if (!props.edited) {
      props.print();
    }
  };

  return (
    <div className="tools-buttons">
      <button
        href="#edit"
        onClick={toggleEditing}
        className="editbutton"
        title="Enable/disable editing"
      >
        Edit
      </button>
      <button
        onClick={props.openDocumentSettings}
        className="documentSettingsbutton"
        title="Adjust document settings"
      >
        Settings
      </button>
      <button
        href="#preview"
        onClick={preview}
        className={'previewbutton' + (props.edited ? '' : ' disabled')}
        disabled={!props.edited}
        title="Update the preview"
      >
        Preview
      </button>
      <button
        href="#print"
        onClick={print}
        className={'printbutton' + (props.edited ? ' disabled' : '')}
        disabled={props.edited}
        title="Print the service or save as PDF"
      >
        PDF
      </button>
      <button
        onClick={props.save}
        className={'savebutton' + (props.edited ? ' disabled' : '')}
        disabled={props.edited}
        title="Save changes"
      >
        Save
      </button>
      <button
        onClick={props.newDocument}
        className="newbutton"
        title="New document (discards existing document)"
      >
        New
      </button>
      <button
        onClick={props.toggleInfo}
        className="infobutton"
        title="More information about Jottle."
      >
        Info
      </button>
      <button
        onClick={props.exit}
        className="exitbutton"
        title="Exit to the dashboard"
      >
        Exit
      </button>
      {props.infoVisible && (
        <InfoDialogue visible={props.infoVisible} bgclick={props.toggleInfo} />
      )}
    </div>
  );
}
