import React from 'react';

import DashboardContainer from '../DashboardContainer';
import CircularProgress from '@material-ui/core/CircularProgress';

import { JottleContext } from '../context/JottleContext';

import dayjs from 'dayjs';

import './userInfo.scss';

export default class UserInfo extends React.Component {
  static contextType = JottleContext;
  state = {
    loading: true,
    userInfo: { email: null, name: null },
    subscriptionInfo: {
      status: null,
      expiry: null,
      cardBrand: null,
      cardExpiry: null,
      cardLast4: null,
    },
  };

  render() {
    return (
      <DashboardContainer title="User information">
        <div className="userInfo">
          {this.state.loading && (
            <div className="loading">
              <CircularProgress size={70} />
            </div>
          )}
          <table>
            <tbody>
              <tr>
                <th>Email address</th>
                <td>{this.state.userInfo.email}</td>
              </tr>
              <tr>
                <th>Name</th>
                <td>{this.state.userInfo.name}</td>
              </tr>
              <tr>
                <th>Subscription status</th>
                <td>{this.state.subscriptionInfo.status}</td>
              </tr>
              <tr>
                <th>Subscription period ends</th>
                <td>
                  {this.state.subscriptionInfo.expiry &&
                    dayjs(this.state.subscriptionInfo.expiry).format(
                      'dddd D MMMM YYYY',
                    )}
                </td>
              </tr>
              <tr>
                <th>Card type</th>
                <td>{this.state.subscriptionInfo.cardBrand}</td>
              </tr>
              <tr>
                <th>Card number</th>
                <td>
                  {this.state.subscriptionInfo.cardLast4 && (
                    <>●●●● ●●●● ●●●● {this.state.subscriptionInfo.cardLast4}</>
                  )}
                </td>
              </tr>
              <tr>
                <th>Card expiry</th>
                <td>
                  {this.state.subscriptionInfo.cardExpiry &&
                    dayjs(this.state.subscriptionInfo.cardExpiry).format(
                      'MM/YY',
                    )}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </DashboardContainer>
    );
  }

  async componentDidMount() {
    const data = await Promise.all([
      this.context.api.getUserInfo(),
      this.context.api.getSubscriptionInfo(),
    ]);

    this.setState({
      userInfo: data[0],
      subscriptionInfo: data[1][0] || {
        status: null,
        expiry: null,
        cardBrand: null,
        cardExpiry: null,
        cardLast4: null,
      },
      loading: false,
    });
  }
}
