import React from 'react';

import Container from '@material-ui/core/Container';
import AppBar from '@material-ui/core/AppBar';
import Paper from '@material-ui/core/Paper';
import { LogoutButton } from './dashboard/dashboardButtons';
import Menu from './Menu';

import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

export default function DashboardContainer(props) {
  return (
    <Container maxWidth="lg">
      <AppBar position="static">
        <Toolbar>
          <Menu />
          <Typography variant="h6">{props.title}</Typography>
          <LogoutButton />
        </Toolbar>
      </AppBar>
      <Paper elevation={10}>{props.children}</Paper>
    </Container>
  );
}
