import React from 'react';
import './modal.css';

export default class Modal extends React.Component {
  bgclick = e => {
    if (this.props.bgclick) {
      if (e.target.classList.contains('modal')) {
        this.props.bgclick();
      }
    }
  };

  render() {
    if (this.props.visible) {
      return (
        <>
          <div className="modal">
            <div className="modal-content">
              <div className="modal-title">{this.props.title}</div>
              {this.props.children}
            </div>
          </div>
        </>
      );
    } else {
      return null;
    }
  }
}
