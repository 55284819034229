import React from 'react';

import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

import getProcessedText from './getProcessedText';

export class BooleanOption extends React.Component {
  handleChange = e => {
    this.props.handleChange(e);
  };

  render() {
    return (
      <FormControlLabel
        key={`option-${this.props.name}`}
        control={
          <Switch
            checked={this.props.value || false}
            className="switch"
            onChange={this.handleChange}
            inputProps={{
              name: this.props.name,
              id: `edit-${this.props.name}`,
            }}
            color="primary"
          />
        }
        label={this.props.details.displayTitle || this.props.name}
        style={{ display: 'block' }}
      />
    );
  }
}

export class ShortTextOption extends React.Component {
  render() {
    return (
      <TextField
        key={`option-${this.props.name}`}
        label={this.props.details.displayTitle || this.props.name}
        autoComplete="off"
        margin="normal"
        placeholder={this.props.details.placeholder}
        value={this.props.value || ''}
        name={this.props.name}
        onKeyPress={e => e.key === 'Enter' && e.ctrlKey && this.props.save()}
        onChange={this.props.handleChange}
        fullWidth
      />
    );
  }
}

export class LongTextOption extends React.Component {
  handlePaste = event => {
    event.preventDefault();

    const processed = getProcessedText(event);
    const name = event.target.name;

    if (processed !== false) {
      console.log('pasting');
      this.props.handleChange({
        target: {
          name,
          value: processed,
        },
      });
      return;
    }

    let existingText = event.target.value,
      selectStart = event.target.selectionStart,
      selectEnd = event.target.selectionEnd;

    let originalText = event.clipboardData.getData('text/plain');
    if (
      !/[^\n] *\n *[^\n]/.test(originalText) &&
      !/[^\n]{120,}/.test(originalText)
    ) {
      originalText = originalText.replace(/\n{2}/g, '\n');
    }

    const newText =
      existingText.substring(0, selectStart) +
      originalText +
      existingText.substring(selectEnd, existingText.length);

    this.props.handleChange({
      target: {
        name: name,
        value: newText,
      },
    });
  };

  render() {
    return (
      <TextField
        key={`option-${this.props.name}`}
        label={this.props.details.displayTitle || this.props.name}
        multiline
        margin="normal"
        value={this.props.value}
        onChange={this.props.handleChange}
        onPaste={this.handlePaste}
        name={this.props.name}
        fullWidth
      />
    );
  }
}

export class PreviewBlock extends React.Component {
  render() {
    return (
      <div
        className={
          'modal-preview ' + (this.props.previewClass ? 'flash' : 'flashA')
        }
      >
        <div className="preview">{this.props.preview}</div>
      </div>
    );
  }
}
