import React from 'react';
import { Redirect } from 'react-router-dom';
import WebFont from 'webfontloader';
import uuid from 'uuid/v4';
import cloneDeep from 'lodash.clonedeep';
import { withSnackbar } from 'notistack';

import Service from './liturgy/Service';
import Tools from './Tools';
import Block from './Block';
import DocumentSettingsDialogue from './DocumentSettingsDialogue';

import { JottleContext } from '../context/JottleContext';

import render from './rendering';

import './liturgyEditor.css';

class LiturgyEditor extends React.Component {
  static contextType = JottleContext;

  constructor(props) {
    super(props);

    this.iframe = React.createRef();
    this.serviceContent = React.createRef();

    this.state = {
      editable: false,
      edited: false,
      documentSettingsOpen: false,
      documentSettings: {
        fontFamily: 'Crimson Text',
      },
      data: [],
      infoVisible: false,
      savedData: [],
    };
  }

  save = () => {
    const clonedData = cloneDeep(this.state);
    this.props.save({
      data: clonedData.data.map(el => {
        delete el.key;
        return el;
      }),
      documentSettings: clonedData.documentSettings,
    });
    this.props.enqueueSnackbar('Saved');
  };

  newDocument = () => {
    this.setState({
      data: [],
      documentSettings: {
        fontFamily: 'Crimson Text',
        theme: 'Dix',
      },
    });
    this.preview();
  };

  updateData = data => {
    if (Array.isArray(data)) {
      for (let item of data) {
        item.key = `${item.type}-${uuid()}`;
      }
    }

    this.setState({
      data,
      edited: true,
    });
  };

  getName = () => {
    return [
      this.state.documentSettings.churchName,
      [
        this.state.documentSettings.serviceTitle,
        this.state.documentSettings.serviceSubTitle,
      ]
        .filter(Boolean)
        .join(' '),
      this.state.documentSettings.date,
    ]
      .filter(Boolean)
      .join(' – ');
  };

  preview = () => {
    this.setState(
      {
        editable: false,
        edited: false,
      },
      () => {
        const iframe = this.iframe.current;

        const iframeSrc = render({
          content: this.serviceContent.current.innerHTML,
          name: this.getName(),
          theme: this.state.documentSettings.theme,
        });

        iframe.contentWindow.document.open();
        iframe.contentWindow.document.write(iframeSrc);
        iframe.contentWindow.document.close();

        this.loadFonts(iframe);
      },
    );
  };

  print = () => {
    const printWindow = window.open('', 'rendered');
    printWindow.print();
  };

  updateDocumentSettings = newSettings => {
    this.setState({
      documentSettings: newSettings,
      edited: true,
    });
  };

  tools = {
    toggleEditing: () => this.setState({ editable: !this.state.editable }),
    preview: this.preview,
    print: this.print,
    openDocumentSettings: () => this.setState({ documentSettingsOpen: true }),
    save: this.save,
    newDocument: this.newDocument,
    toggleInfo: () => this.setState({ infoVisible: !this.state.infoVisible }),
    exit: () => this.setState({ exit: true }),
  };

  render() {
    if (!this.props.test && this.state.exit) {
      return <Redirect to="/dashboard" push />;
    }
    if (this.props.test) {
      if (this.state.exit) {
        return <Redirect to="/" push />;
      } else {
        this.props.enqueueSnackbar(
          `You are using the free version of Jottle. You have full access to all
          the functionality, but you can only save one file at a time and it
          will only be accessible from your current computer.`,
          { preventDuplicate: true, autoHideDuration: 10000 },
        );
      }
    }
    if (!this.props.test && !this.context.api.isLoggedIn()) {
      return <Redirect to="/login" />;
    }

    return (
      <div className="editor">
        <div className={'edit' + (this.state.editable ? ' active' : '')}>
          <div className="paneTitle">Edit</div>
          <Service
            updateData={this.updateData}
            data={this.state.data}
            editable={this.state.editable}
            documentSettings={this.state.documentSettings || {}}
            serviceRef={this.serviceContent}
          />
        </div>
        <div className={'preview' + (this.state.edited ? ' edited' : '')}>
          <Block blocked={this.state.edited}>
            <p>The content has changed since the last preview.</p>
            <p>Click “Preview” to view the latest changes.</p>
            <button className="btnPreview" onClick={this.preview}>
              Preview
            </button>
          </Block>
          <div className="paneTitle">Preview</div>
          <iframe
            title="rendered"
            name="rendered"
            ref={this.iframe}
            className={this.state.edited ? 'edited' : ''}
          />
        </div>
        <div className="tools">
          <Tools
            infoVisible={this.state.infoVisible}
            edited={this.state.edited}
            {...this.tools}
          />
        </div>
        {this.state.documentSettingsOpen && (
          <DocumentSettingsDialogue
            open={this.state.documentSettingsOpen}
            documentSettings={this.state.documentSettings}
            save={this.updateDocumentSettings}
            close={() => this.setState({ documentSettingsOpen: false })}
          />
        )}
      </div>
    );
  }

  loadFonts(iframe = null) {
    const fontList = [
      this.state.documentSettings.fontFamily,
      this.state.documentSettings.headingFontFamily,
    ];

    const custom = [];

    if (!iframe) {
      custom.push('Symbola');
    }

    // OpenDyslexic is loaded from fonts.jottle.io
    // Gill Sans is never loaded because of copyright
    // Roboto is loaded as an interface font
    const notGoogle = ['OpenDyslexic', 'Gill Sans', 'Roboto'];

    const google = fontList.reduce((list, fontName) => {
      if (fontName && !notGoogle.includes(fontName)) {
        list.push(fontName + ':400,400i,700,700i');
      }
      return list;
    }, []);

    if (fontList.includes('OpenDyslexic')) {
      custom.push('OpenDyslexic');
    }

    const settings = {
      classes: false,
      events: false,
    };
    if (google.length) {
      settings.google = {
        families: [...google, '&display=swap'],
      };
    }
    if (custom.length) {
      settings.custom = {
        families: custom,
        urls: ['https://fonts.jottle.io/fonts.css'],
      };
    }
    if (iframe) {
      settings.context = iframe.contentWindow;
    }

    WebFont.load(settings);
  }

  loadContent(content) {
    if (!content.data) {
      content.data = [];
    }
    for (let item of content.data) {
      item.key = `${item.type}-${uuid()}`;
    }
    this.setState(
      {
        data: content.data,
        documentSettings: content.documentSettings || {},
      },
      () => this.preview(),
    );
  }

  componentDidUpdate() {
    this.loadFonts();
  }

  componentDidMount() {
    this.loadFonts();
    this.preview();

    Promise.resolve(this.props.content).then(service =>
      this.loadContent(service),
    );
  }
}

export default withSnackbar(LiturgyEditor);
