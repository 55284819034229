import React from 'react';
import JottleAPI from '../api/api.js';

const host =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:8888'
    : 'https://api.jottle.io';

export const JottleContext = React.createContext({
  api: new JottleAPI(host),
});
