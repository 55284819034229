export default class JottleAPI {
  constructor(baseUrl, storageName = 'jottletoken') {
    this.baseUrl = baseUrl.replace(/\/$/, '') + '/';
    this.storageName = storageName;
    this.token = window.localStorage.getItem(storageName) || '';
  }

  isLoggedIn() {
    return !!this.token;
  }

  request(url, userSettings, authorisation = true) {
    const settings = Object.assign(
      {
        method: 'get',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
        },
      },
      userSettings,
    );

    if (settings.body && typeof settings.body !== 'string') {
      settings.body = JSON.stringify(settings.body);
    }

    if (authorisation) {
      settings.headers.Authorization = `Bearer ${this.token}`;
    }

    return fetch(url, settings);
  }

  async login(email, password) {
    let response;
    try {
      response = await this.request(
        `${this.baseUrl}login`,
        {
          method: 'post',
          body: { email, password },
        },
        false,
      );
    } catch (e) {
      console.log(e);
      throw new Error('Login failed (server unavailable)');
    }

    const parsedResponse = await response.json();

    if (!response.ok || parsedResponse.error) {
      throw new Error(`Login failed (${parsedResponse.message})`);
    }

    this.token = parsedResponse.token;
    window.localStorage.setItem(this.storageName, this.token);

    return true;
  }

  logout() {
    this.token = null;
    window.localStorage.removeItem(this.storageName);
  }

  async validateToken() {
    if (!this.token) {
      return false;
    }

    let response;
    try {
      response = await this.request(this.baseUrl + 'validateToken');
    } catch {
      throw new Error('Server unavailable');
    }

    if (!response.ok) {
      delete this.token;
    }

    return response.ok;
  }

  async listServices() {
    try {
      const response = await this.request(this.baseUrl + 'services');

      return response.json();
    } catch {
      throw new Error('Server unavailable');
    }
  }

  async getService(hash) {
    let response;
    try {
      response = await this.request(this.baseUrl + 'services/' + hash);
    } catch {
      throw new Error('Server unavailable');
    }

    if (!response.ok) {
      throw new Error('Service not found');
    }

    return (await response.json())[0];
  }

  async listServiceVersions(hash) {
    let response;
    try {
      response = await this.request(`${this.baseUrl}services/${hash}/versions`);
    } catch {
      throw new Error('Server unavailable');
    }

    if (!response.ok) {
      throw new Error('Service not found');
    }

    return response.json();
  }

  async getServiceVersion(serviceHash, versionHash) {
    let response;
    try {
      response = await this.request(
        `${this.baseUrl}services/${serviceHash}/versions/${versionHash}`,
      );
    } catch {
      throw new Error('Server unavailable');
    }

    if (!response.ok) {
      throw new Error('Version not found');
    }

    return response.json();
  }

  async createService(name, content) {
    let response;

    try {
      response = await this.request(`${this.baseUrl}services`, {
        method: 'PUT',
        body: JSON.stringify({ content, name }),
      });
    } catch {
      throw new Error('Server unavailable');
    }

    if (!response.ok) {
      throw new Error('Service creation failed');
    }

    return (await response.json()).hash;
  }

  async updateService(hash, content) {
    let response;

    try {
      response = await this.request(`${this.baseUrl}services/${hash}`, {
        method: 'post',
        body: JSON.stringify(content),
      });
    } catch (e) {
      console.log(e);
      throw new Error('Server unavailable');
    }

    if (!response.ok) {
      throw new Error('Service update failed');
    }

    return (await response.json()).hash;
  }

  async renameService(hash, name) {
    let response;

    try {
      response = await this.request(`${this.baseUrl}services/${hash}/name`, {
        method: 'POST',
        body: { name },
      });
    } catch {
      throw new Error('Server unavailable');
    }

    if (!response.ok) {
      throw new Error('Service name changed failed');
    }

    return true;
  }

  async deleteService(hash) {
    let response;

    try {
      response = await this.request(`${this.baseUrl}services/${hash}`, {
        method: 'DELETE',
      });
    } catch {
      throw new Error('Server unavailable');
    }

    if (!response.ok) {
      throw new Error('Service deletion failed');
    }

    return true;
  }

  async createUser({ name, email, password }) {
    let response;

    try {
      response = await this.request(`${this.baseUrl}user/create`, {
        method: 'POST',
        body: { name, email, password },
      });

      response = await response.json();
    } catch {
      throw new Error('Server unavailable');
    }

    if (!response.sessionId) {
      throw new Error('User could not be created');
    }

    return response.sessionId;
  }

  async getUserInfo() {
    try {
      const response = await this.request(`${this.baseUrl}user/info`);

      return response.json();
    } catch {
      throw new Error('Server unavailable');
    }
  }

  async getSubscriptionInfo() {
    try {
      const response = await this.request(`${this.baseUrl}user/subscription`);

      return response.json();
    } catch {
      throw new Error('Server unavailable');
    }
  }
}
