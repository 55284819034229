import React from 'react';
import { Redirect } from 'react-router-dom';

import { JottleContext } from './context/JottleContext';

import LiturgyEditor from './liturgyEditor/LiturgyEditor';

class NewLiturgy extends React.Component {
  static contextType = JottleContext;
  state = {};

  componentDidMount() {
    this.context.api
      .createService('Test name', {
        data: [],
        documentSettings: {},
      })
      .then(hash => this.setState({ hash }));
  }

  render() {
    if (this.state.hash) {
      return <Redirect to={`/services/${this.state.hash}/edit`} push />;
    } else {
      return null;
    }
  }
}

export default class EditLiturgy extends React.Component {
  static contextType = JottleContext;

  save = (hash, content) => {
    this.context.api
      .updateService(hash, content)
      .then(response => console.log(response));
  };

  render() {
    let content;
    if (this.props.hash) {
      content = this.context.api
        .getService(this.props.hash)
        .then(service => service.ServiceContent);

      return (
        <LiturgyEditor
          save={content => this.save(this.props.hash, content)}
          content={content}
        />
      );
    } else if (this.props.test) {
      let savedData = JSON.parse(
        window.localStorage.getItem('jottleStoredData') || '{}',
      );

      if (savedData.data) {
        content = savedData;
      } else {
        content = fetch(this.props.ajax).then(result => result.json());
      }

      const save = doc => {
        window.localStorage.setItem('jottleStoredData', JSON.stringify(doc));
      };

      return <LiturgyEditor test save={save} content={content} />;
    } else if (this.props.new) {
      return <NewLiturgy />;
    }
  }
}
