import React from 'react';
import cloneDeep from 'lodash.clonedeep';
import ReferenceParser from 'referenceparser';

import Editable from './Editable';
import { Heading3, Heading2 } from './Headings';
import { People } from './People';

import { breakbefore } from './breakbefore';

import './psalm.css';

import { formatTextSmart as formatText } from './formatText';
import formatReference from './formatReference';

const rp = new ReferenceParser();

export function Psalm(props) {
  let output;

  const numbered = /^\d+\s*/gm.test(props.text);

  const Heading = Boolean(props.multiplePsalms) ? Heading3 : Heading2;
  const reference = Boolean(props.reference) ? (
    <div className="reference">
      {formatReference(rp.parseComplexReference(props.reference))}
    </div>
  ) : null;

  if (numbered) {
    output = Array.from(
      props.text.matchAll(/(\d+)\s*([\s\S]+?)(?=\n*\d|$)/g),
    ).reduce((accumulator, [whole, verseNumber, verseText], idx) => {
      accumulator.push(
        <div className="verse" data-verse-number={verseNumber} key={idx}>
          {formatText(verseText)}
        </div>,
      );

      return accumulator;
    }, []);
  } else {
    output = props.text
      .trim()
      .split(/\n\s*\n/)
      .map((verseText, idx) => {
        return (
          <div className="verse" key={idx}>
            {formatText(verseText)}
          </div>
        );
      });
  }

  const antiphon = (props.antiphon || '').replace(/\//g, '\n');

  return (
    <div
      className={
        'psalm' +
        (numbered ? ' numbered' : '') +
        (props.breakbefore ? ' breakbefore' : '')
      }
    >
      {props.heading ? <Heading content={props.heading} /> : null}
      {antiphon ? <People content={antiphon} /> : null}
      {output}
      {reference}
      {props.gloriaPatri ? (
        <People
          content={`Glory to the Father and to the Son\nand to the Holy Spirit;\nas it was in the beginning, is now\nand shall be for ever. Amen`}
        />
      ) : null}
      {antiphon ? <People content={antiphon} /> : null}
    </div>
  );
}

Psalm.displayName = 'Psalm';

export function EditablePsalm(props) {
  const rendering = <Psalm {...props} />;

  const { description, ...existingData } = cloneDeep(data);

  existingData.heading.value = props.heading;
  existingData.antiphon.value = props.antiphon;
  existingData.text.value = props.text;
  existingData.reference.value = props.reference;
  existingData.multiplePsalms.value = props.multiplePsalms;
  existingData.gloriaPatri.value = props.gloriaPatri;
  existingData.breakbefore.value = props.breakbefore;

  const actions = {
    moveUp: props.moveUp,
    moveDown: props.moveDown,
    save: props.save,
    delete: props.delete,
    add: props.add,
  };

  return (
    <Editable
      description={description}
      data={existingData}
      {...actions}
      rendering={rendering}
      type="psalm"
      preview={Psalm}
      documentSettings={props.documentSettings}
    />
  );
}

export const data = Object.freeze({
  description:
    'For formatting psalms (except responsorial psalms). The antiphon is optional. The ‘reference’ field will be treated as a biblical reference. If the text has numbers, they will be treated as verse numbers. If not, you should leave a blank line between each verse/stanza. ‘Display as one of several psalms’ makes the heading a third-level heading, so you can have a second-level ‘Psalmody’ heading if you wish.',
  heading: {
    type: 'shorttext',
    value: '',
    placeholder: 'Heading, e.g. ‘Psalm 23’',
    displayTitle: 'Heading',
  },
  antiphon: {
    type: 'shorttext',
    value: '',
    placeholder: 'Antiphon/refrain for the psalm (use a slash / to insert a line break)',
    displayTitle: 'Antiphon',
  },
  text: {
    type: 'text',
    value: '',
    displayTitle: 'Text',
  },
  reference: {
    type: 'shorttext',
    value: '',
    displayTitle: 'Reference',
    placeholder: 'Scriptural reference (if desired)',
  },
  gloriaPatri: {
    type: 'boolean',
    value: false,
    displayTitle: `Include the ‘Glory to the Father’?`,
  },
  multiplePsalms: {
    type: 'boolean',
    value: false,
    displayTitle: `Display as one of several psalms?`,
  },
  breakbefore,
});
