import React from 'react';
import cloneDeep from 'lodash.clonedeep';

import Modal from '../Modal';
import { data, types } from './liturgyComponents';
import DialogueButton from '../DialogueButton';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

import {
  ShortTextOption,
  LongTextOption,
  BooleanOption,
  PreviewBlock,
} from './editComponents';

import './addDialogue.css';

class WhatTypeDialogue extends React.Component {
  state = { choice: '' };
  types = types;

  apply = () => {
    this.props.apply(this.state.choice);
  };
  handleChange = e => {
    this.setState({ choice: e.target.value });
  };

  render() {
    return (
      <Dialog open={this.props.open} onClose={this.props.cancel}>
        <DialogTitle>Add element to the service</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Choose what type of content you wish to add.
          </DialogContentText>
          <Select
            value={this.state.choice}
            onChange={this.handleChange}
            autoWidth={true}
            displayEmpty={true}
            variant="outlined"
            style={{ width: '80%' }}
          >
            {Object.entries(this.types).map(([name, type]) => {
              return (
                <MenuItem value={name} key={name}>
                  {type.displayName}
                </MenuItem>
              );
            })}
          </Select>
        </DialogContent>
        <DialogActions>
          <Button color="secondary" onClick={this.props.cancel}>
            Cancel
          </Button>
          <Button color="primary" variant="contained" onClick={this.apply}>
            Select
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default class AddDialogue extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      type: null,
    };
  }

  listTypes = () => {
    return Object.keys(data);
  };

  chooseType = type => {
    const newData = cloneDeep(data[type]);

    this.setState({ type, data: newData });
  };

  cancel = () => {
    this.setState({
      type: null,
      data: {},
    });

    this.props.endAdding();
  };

  save = () => {
    const saveData = {};

    for (let [name, data] of Object.entries(this.state.data)) {
      saveData[name] = data.value;
    }

    saveData.type = this.state.type;

    this.props.save(saveData);

    this.props.endAdding();

    this.setState({
      type: null,
      data: {},
    });
  };

  handleChange = name => event => {
    const data = cloneDeep(this.state.data);

    if (data[name].type === 'boolean') {
      data[name].value = event.target.checked;
    } else {
      data[name].value = event.target.value;
    }

    this.setState({
      data,
    });
  };

  whatTypeDialogue = () => {
    return (
      <WhatTypeDialogue
        open={true}
        cancel={this.cancel}
        apply={type => this.chooseType(type)}
      />
    );
  };

  typeDialogue = () => {
    let previewData = Object.entries(this.state.data).reduce(
      (acc, [key, value]) => {
        acc[key] = value.value;
        return acc;
      },
      {},
    );

    const preview = React.createElement(types[this.state.type], {
      documentSettings: this.props.documentSettings,
      ...previewData,
    });

    const options = Object.entries(this.state.data)
      .filter(data => data[0] !== 'description')
      .map(([name, details], idx) => {
        if (details.type === 'text') {
          return (
            <LongTextOption
              details={details}
              name={name}
              value={details.value}
              handleChange={this.handleChange(name)}
              save={this.save}
              key={`options-${name}`}
            />
          );
        }
        if (details.type === 'shorttext') {
          return (
            <ShortTextOption
              details={details}
              name={name}
              value={details.value}
              handleChange={this.handleChange(name)}
              save={this.save}
              key={`options-${name}`}
            />
          );
        }
        if (details.type === 'boolean') {
          return (
            <BooleanOption
              details={details}
              name={name}
              value={details.value}
              handleChange={this.handleChange(name)}
              save={this.save}
              key={`options-${name}`}
            />
          );
        }
        return null;
      });

    return (
      <Modal title={'Edit'} visible={true}>
        <div
          className="modal-summary"
          dangerouslySetInnerHTML={{
            __html: data[this.state.type].description,
          }}
        />
        <PreviewBlock
          preview={preview}
          documentSettings={this.props.documentSettings}
        />
        <div className="modal-options">{options}</div>
        <div className="modal-buttons">
          <DialogueButton onClick={this.props.close}>Cancel</DialogueButton>
          <DialogueButton onClick={this.save}>Save</DialogueButton>
        </div>
      </Modal>
    );
  };

  render() {
    if (!this.props.open) return null;
    if (!this.state.type) {
      return this.whatTypeDialogue();
    } else {
      return this.typeDialogue();
    }
  }
}
