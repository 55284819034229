export default function render({ content, title, theme }) {
  return `<html><head><title>
      ${title || 'Jottle: Simple, beautiful liturgy'}
      </title>
      <link rel="stylesheet" href="/all.css?v=${process.env.REACT_APP_GIT_SHA}">
      <script src="https://unpkg.com/pagedjs@0.1.34/dist/paged.polyfill.js"></script>
      </head><body><div id="root" data-theme="${theme}">
        ${content}
        </div>
      </body></html>`;
}
