import React from 'react';
import { Redirect } from 'react-router-dom';

import dayjs from 'dayjs';

import ButtonGroup from '@material-ui/core/ButtonGroup';
import CircularProgress from '@material-ui/core/CircularProgress';

import DashboardContainer from '../DashboardContainer';

import {
  NewButton,
  EditButton,
  DeleteButton,
  DuplicateButton,
  RenameButton,
} from './dashboardButtons';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import { withSnackbar } from 'notistack';

import { JottleContext } from '../context/JottleContext';

import './dashboard.scss';

function ServiceLine({
  service,
  delete: fnDelete,
  rename,
  duplicate,
  setLoading,
}) {
  return (
    <TableRow hover={true}>
      <TableCell component="th" scope="row">
        {service.name}
      </TableCell>
      <TableCell>{service.hash}</TableCell>
      <TableCell>
        <time className="created">
          {dayjs(service.created).format('YYYY/MM/DD HH:mm')}
        </time>
      </TableCell>
      <TableCell>
        <time className="updated">
          {dayjs(service.updated).format('YYYY/MM/DD HH:mm')}
        </time>
      </TableCell>
      <TableCell>
        <ButtonGroup size="small">
          <EditButton hash={service.hash} setLoading={setLoading} />
          <RenameButton
            hash={service.hash}
            name={service.name}
            rename={rename}
          />
          <DuplicateButton name={service.name} duplicate={duplicate} />
          <DeleteButton
            hash={service.hash}
            delete={fnDelete}
            name={service.name}
          />
        </ButtonGroup>
      </TableCell>
    </TableRow>
  );
}

class Dashboard extends React.Component {
  listRef = React.createRef();
  state = {
    services: [],
    loading: true,
  };
  static contextType = JottleContext;

  delete = delIdx => {
    const service = this.state.services[delIdx];
    this.context.api.deleteService(service.hash);
    this.props.enqueueSnackbar(`‘${service.name}’ deleted.`);

    this.setState({
      services: this.state.services.filter((el, idx) => {
        return delIdx !== idx;
      }),
    });
  };

  rename = (renameIdx, newName) => {
    this.setState({
      services: this.state.services.map((el, idx) => {
        if (renameIdx === idx) {
          el.name = newName;
        }
        return el;
      }),
    });
  };

  duplicate = idx => async newName => {
    const service = this.state.services[idx];

    try {
      const currentContent = await this.context.api.getService(service.hash);

      await this.context.api.createService(
        newName,
        currentContent.ServiceContent,
      );

      this.props.enqueueSnackbar('Service duplicated', {
        variant: 'success',
      });
      this.reload();
    } catch (e) {
      console.log(e);
      this.props.enqueueSnackbar('Service duplication failed', {
        variant: 'error',
      });
    }
  };

  setLoading = (loading = true) => {
    this.setState({ loading });
  };

  render() {
    if (!this.context.api.isLoggedIn()) {
      return <Redirect to="/login" />;
    }

    const serviceList = this.state.services.map((service, idx) => {
      return (
        <ServiceLine
          service={service}
          key={service.hash}
          delete={() => this.delete(idx)}
          rename={name => this.rename(idx, name)}
          duplicate={this.duplicate(idx)}
          setLoading={this.setLoading}
        />
      );
    });

    return (
      <div className="dashboard app">
        <DashboardContainer title="Services">
          <div className="dashboard">
            {this.state.loading && (
              <div className="loading">
                <CircularProgress size={70} />
              </div>
            )}
            <Table padding="default">
              <TableHead>
                <TableRow>
                  <TableCell>Service</TableCell>
                  <TableCell></TableCell>
                  <TableCell>First created</TableCell>
                  <TableCell>Last updated</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>{serviceList}</TableBody>
            </Table>
            <NewButton />
          </div>
        </DashboardContainer>
      </div>
    );
  }

  async componentDidMount() {
    const services = await this.context.api.listServices();

    this.setState({ services, loading: false });
  }

  async reload() {
    this.setState({ loading: true });
    this.setState({
      services: await this.context.api.listServices(),
      loading: false,
    });
  }
}

export default withSnackbar(Dashboard);
