import React from 'react';
import typographicBase from 'typographic-base';

const newlineRegex = /(\r\n|\r|\n)/g;

export function formatText(str, depth = 1) {
  if (typeof str === 'number') return str;
  if (typeof str !== 'string') return '';

  if (depth === 1) {
    str = str.trim();
  }

  str = typographicBase(str, { locale: 'en-uk' });
  str = str.replace(/(\d)-end/, (match, number) => {
    return number + '–end';
  });

  const matches = str.match(/^([\s\S]*?)\*([\s\S]+?)\*( ?)([\s\S]*)$/m);

  /*
   * matches
   * 1 is the part before the first asterisk
   * 2 is the part between the asterisks
   * 3 is the space immediately after the second asterisk, if one exists
   * 4 is the part after the second asterisk
   */

  if (!matches) {
    return addNewLines(str, depth);
  } else {
    return [
      matches[1].trim().length ? addNewLines(matches[1], depth) : null,
      <em key={'em-' + depth}>{addNewLines(matches[2], depth)}</em>,
      matches[3],
      ...formatText(matches[4], depth + 1),
    ];
  }
}

function addNewLines(str, depth) {
  return str.split(newlineRegex).map(function(line, idx) {
    if (line.match(newlineRegex)) {
      return <br key={'br-' + idx} />;
    }
    if (line.trim().length === 0) return null;
    if (/^ +/.test(line)) {
      line = (
        <>
          <span className="indent" />
          {line.trim()}
        </>
      );
    }
    return (
      <span className="line" key={'line-' + depth + '-' + idx}>
        {line}
      </span>
    );
  });
}

export const formatTextSmart = formatText;

export function simpleFormatText(text) {
  return typographicBase(text || '', { locale: 'en-uk' });
}
