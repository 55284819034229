import React from 'react';
import { formatTextSmart } from './formatText';
import cloneDeep from 'lodash.clonedeep';

import Editable from './Editable';

import { breakbefore } from './breakbefore';

import './people.css';

export class EditablePeople extends React.Component {
  render() {
    const rendering = <People {...this.props} />;

    const { description, ...existingData } = cloneDeep(data);

    existingData.content.value = this.props.content;
    existingData['no all'].value = this.props['no all'];
    existingData.breakbefore.value = this.props.breakbefore;
    existingData.noIndent.value = this.props.noIndent;

    const actions = {
      moveUp: this.props.moveUp,
      moveDown: this.props.moveDown,
      save: this.props.save,
      delete: this.props.delete,
      add: this.props.add,
    };

    return (
      <Editable
        description={description}
        data={existingData}
        rendering={rendering}
        type="people"
        {...actions}
        preview={People}
        documentSettings={this.props.documentSettings}
      />
    );
  }
}

export class People extends React.Component {
  static displayName = 'People';

  render() {
    const paragraphs = this.props.content.trim().split(/\n\s*\n/);
    const output = paragraphs.map((paragraph, idx) => {
      const noall = this.props['no all'] || idx > 0;
      const breakbefore = idx === 0 && this.props.breakbefore;

      const classes = [
        'people',
        ...(breakbefore ? ['breakbefore'] : []),
        ...(this.props.noIndent ? ['noindent'] : []),
      ];

      return (
        <div key={idx} className={classes.join(' ')} data-no-all={noall}>
          {formatTextSmart(paragraph.trim())}
        </div>
      );
    });

    return <>{output}</>;
  }
}

export const data = Object.freeze({
  description:
    'Words spoken by all or some of the congregation. The text is in bold and, unless it is disabled, the word ‘All’ is placed in the margin. Italicisation can be applied by putting the text between asterisks *like this*. This style should not be used for hymns.',
  content: {
    type: 'text',
    value: '',
    displayTitle: 'Content',
  },
  'no all': {
    type: 'boolean',
    value: false,
    displayTitle: 'Omit the "All"',
  },
  noIndent: {
    type: 'boolean',
    value: false,
    displayTitle: 'Don’t indent extra-long lines',
  },
  breakbefore,
});
