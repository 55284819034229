import { People, EditablePeople, data as peopleData } from './People';
import { Minister, EditableMinister, data as ministerData } from './Minister';
import {
  Heading1,
  Heading2,
  Heading3,
  EditableHeading1,
  EditableHeading2,
  EditableHeading3,
  data as headingData,
} from './Headings';
import { Hymn, EditableHymn, data as hymnData } from './Hymn';
import {
  Reading,
  EditableReading,
  GospelReading,
  EditableGospelReading,
  readingData,
  gospelData,
} from './Reading';
import { Rubric, EditableRubric, data as rubricData } from './Rubric';
import {
  ResponsorialPsalm,
  EditableResponsorialPsalm,
  data as responsorialPsalmData,
} from './ResponsorialPsalm';
import { Psalm, EditablePsalm, data as psalmData } from './Psalm';
import { Music, EditableMusic, data as musicData } from './Music';
import {
  Copyright,
  EditableCopyright,
  data as copyrightData,
} from './Copyright';

import './liturgy.scss';
import '../themes/wesley.scss';
import '../themes/gregory.scss';

export const editableTypes = {
  Heading1: EditableHeading1,
  Heading2: EditableHeading2,
  Heading3: EditableHeading3,
  Hymn: EditableHymn,
  Minister: EditableMinister,
  Music: EditableMusic,
  People: EditablePeople,
  Psalm: EditablePsalm,
  ResponsorialPsalm: EditableResponsorialPsalm,
  Rubric: EditableRubric,
  Reading: EditableReading,
  GospelReading: EditableGospelReading,
  Copyright: EditableCopyright,
};

export const types = {
  Heading1,
  Heading2,
  Heading3,
  Hymn,
  Minister,
  Music,
  People,
  Psalm,
  ResponsorialPsalm,
  Rubric,
  Reading,
  GospelReading,
  Copyright,
};

export const data = {
  Heading1: headingData,
  Heading2: headingData,
  Heading3: headingData,
  Hymn: hymnData,
  Minister: ministerData,
  Music: musicData,
  People: peopleData,
  Psalm: psalmData,
  ResponsorialPsalm: responsorialPsalmData,
  Rubric: rubricData,
  Reading: readingData,
  GospelReading: gospelData,
  Copyright: copyrightData,
};
