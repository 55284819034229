import React from 'react';
import Modal from '../Modal';

import DialogueButton from '../DialogueButton';
import {
  ShortTextOption,
  LongTextOption,
  BooleanOption,
  PreviewBlock,
} from './editComponents';

import './editDialogue.scss';

export default class EditDialogue extends React.Component {
  constructor(...args) {
    super(...args);
    this.state = {
      data: { ...this.props.data },
    };
  }

  save = () => {
    const saveData = {};

    for (let [name, data] of Object.entries(this.state.data)) {
      saveData[name] = data.value;
    }

    this.props.save(saveData);

    this.props.close();
  };

  handleChange = name => event => {
    const data = { ...this.state.data };

    if (data[event.target.name].type === 'boolean') {
      data[event.target.name].value = event.target.checked;
    } else {
      data[event.target.name].value = event.target.value;
    }

    this.setState({
      data,
    });
  };

  render() {
    if (!this.props.open) {
      return null;
    }

    let previewData = Object.entries(this.state.data).reduce(
      (acc, [key, value]) => {
        acc[key] = value.value;
        return acc;
      },
      {},
    );

    const preview = React.createElement(this.props.preview, {
      documentSettings: this.props.documentSettings,
      ...previewData,
    });

    const options = Object.entries(this.state.data)
      .filter(data => data[0] !== 'description')
      .map(([name, details], idx) => {
        if (details.type === 'text') {
          return (
            <LongTextOption
              details={details}
              name={name}
              value={details.value}
              handleChange={this.handleChange(name)}
              save={this.save}
              key={`options-${name}`}
            />
          );
        }
        if (details.type === 'shorttext') {
          return (
            <ShortTextOption
              details={details}
              name={name}
              value={details.value}
              handleChange={this.handleChange(name)}
              save={this.save}
              key={`options-${name}`}
            />
          );
        }
        if (details.type === 'boolean') {
          return (
            <BooleanOption
              details={details}
              name={name}
              value={details.value}
              handleChange={this.handleChange(name)}
              save={this.save}
              key={`options-${name}`}
            />
          );
        }
        return null;
      });

    return (
      <Modal title={'Edit'} visible={true}>
        <div
          className="modal-summary"
          dangerouslySetInnerHTML={{ __html: this.props.description }}
        />
        <PreviewBlock
          preview={preview}
          documentSettings={this.props.documentSettings}
        />
        <div className="modal-options">{options}</div>
        <div className="modal-buttons">
          <DialogueButton onClick={this.props.close}>Cancel</DialogueButton>
          <DialogueButton onClick={this.save}>Save</DialogueButton>
        </div>
      </Modal>
    );
  }
}
