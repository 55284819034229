export default function getProcessedText(event) {
  let originalText;
  if (
    event &&
    event.clipboardData &&
    event.clipboardData.types &&
    event.clipboardData.getData
  ) {
    const types = event.clipboardData.types;
    if (
      (types instanceof DOMStringList && types.contains('text/html')) ||
      (types.indexOf && types.indexOf('text/html') !== -1)
    ) {
      originalText = event.clipboardData.getData('text/html');
    } else {
      return false;
    }
  } else {
    return false;
  }

  const container = document.createElement('div');
  container.innerHTML = originalText;

  if (!container.querySelector('p')) return false; // we assume p tags after this

  if (container.querySelector('.ve1, .vein')) {
    // Common Worship
    return processCommonWorship(container);
  } else if (container.querySelector('.bibletext')) {
    return processOremus(container);
  } else if (container.querySelector(':scope > p > br')) {
    // probably Hymnary
    return processHasLineBreaks(container);
  } else {
    return false; // follow the default
  }
}

function processOremus(container) {
  return false; // no useful formatting can be done on Oremus texts
}

function processCommonWorship(container) {
  const paras = [];
  let curPara = '';

  let all = false;
  for (let child of container.children) {
    if (child.nodeName !== 'P') continue;
    if (child.classList.contains('veall')) {
      child.removeChild(child.firstElementChild);
      all = true;
    }

    if (
      (!all && child.classList.contains('vein')) ||
      (all && /^\s+/.test(child.innerText))
    ) {
      // indent
      curPara += '   '; // the number of spaces doesn't matter, but 3 looks better
    }

    curPara += child.innerText.trim() + '\n';

    if (
      child.classList.contains('linespace1') ||
      child.classList.contains('linespace2')
    ) {
      paras.push(curPara);
      curPara = '';
    }
  }
  paras.push(curPara);

  return paras.join('\n').trim();
}

function processHasLineBreaks(container) {
  const paras = [];
  let curPara = '';

  for (let child of container.children) {
    if (child.nodeName !== 'P') continue;

    for (let node of child.childNodes) {
      if (node.nodeType === Node.TEXT_NODE) {
        curPara += node.nodeValue.trim();
      } else if (node.nodeType === Node.ELEMENT_NODE) {
        if (node.tagName === 'BR') {
          curPara += '\n';
        }
      }
    }

    curPara = curPara.replace(/^\d+\.?\s*/, '');

    paras.push(curPara);
    curPara = '';
  }

  return paras.join('\n').trim();
}
