import React from 'react';
import { Redirect } from 'react-router-dom';

import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import AppBar from '@material-ui/core/AppBar';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';

import { withSnackbar } from 'notistack';

import { JottleContext } from './context/JottleContext';

import './login.scss';

class Login extends React.Component {
  static contextType = JottleContext;
  state = {};

  doLogin = async () => {
    try {
      let loggedIn = await this.context.api.login(
        this.state.email,
        this.state.password,
      );

      if (!loggedIn) {
        throw new Error('Failed login');
      }
    } catch (e) {
      console.log(e);
      this.props.enqueueSnackbar('Login failed', { variant: 'error' });
      this.setState({ message: 'Login failed' });
      return;
    }

    this.setState({ loggedIn: true });
    this.props.enqueueSnackbar('Logged in.', { variant: 'success' });
  };

  render() {
    if (this.state.loggedIn || this.context.api.token) {
      return <Redirect to="/dashboard" />;
    }

    return (
      <div className="app">
        <Container maxWidth="lg">
          <AppBar position="static">
            <Toolbar>
              <IconButton edge="start" color="inherit" aria-label="menu">
                <MenuIcon />
              </IconButton>
              <Typography variant="h6">Jottle</Typography>
            </Toolbar>
          </AppBar>
          <Paper elevation={10}>
            <div className="login">
              <Container maxWidth="sm">
                <TextField
                  autoFocus
                  fullWidth
                  onChange={e => this.setState({ email: e.target.value })}
                  label="Email address"
                  onKeyPress={e => e.key === 'Enter' && this.doLogin()}
                />
                <TextField
                  fullWidth
                  onChange={e => this.setState({ password: e.target.value })}
                  label="Password"
                  type="password"
                  onKeyPress={e => e.key === 'Enter' && this.doLogin()}
                />
                <Button
                  color="primary"
                  variant="contained"
                  onClick={this.doLogin}
                >
                  Log in
                </Button>
              </Container>
            </div>
          </Paper>
        </Container>
      </div>
    );
  }
}

export default withSnackbar(Login);
