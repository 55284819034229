import React from 'react';
import { Redirect } from 'react-router-dom';

import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

export default function SimpleMenu(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [redirect, setRedirect] = React.useState(null);

  function handleClick(e) {
    setAnchorEl(e.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  function goToDashboard() {
    setRedirect(<Redirect to="/dashboard" push />);
    setAnchorEl(null);
  }

  function goToUserInfo() {
    setRedirect(<Redirect to="/user" push />);
    setAnchorEl(null);
  }

  return (
    <>
      {redirect}
      <IconButton
        edge="start"
        color="inherit"
        aria-label="menu"
        onClick={handleClick}
      >
        <MenuIcon />
      </IconButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={goToDashboard}>Service dashboard</MenuItem>
        <MenuItem onClick={goToUserInfo}>My profile</MenuItem>
      </Menu>
    </>
  );
}
