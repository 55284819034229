import React from 'react';
import { withSnackbar } from 'notistack';

import DashboardContainer from '../DashboardContainer';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';

import TextField from '@material-ui/core/TextField';

import { JottleContext } from '../context/JottleContext';

import './subscribe.scss';

class Subscribe extends React.Component {
  static contextType = JottleContext;
  state = {
    loading: false,
    name: '',
    password: '',
    email: '',
    confirmPassword: '',
  };

  activate = async evt => {
    evt.preventDefault();
    this.setState({ loading: true });

    const { name, email, password, confirmPassword } = this.state;

    if (password !== confirmPassword) {
      this.props.enqueueSnackbar('Passwords do not match', {
        variant: 'error',
      });
      this.setState({ loading: false });
      return;
    }

    let sessionId;

    try {
      sessionId = await this.context.api.createUser({
        name,
        email,
        password,
      });
    } catch {
      this.props.enqueueSnackbar('User creation failed', { variant: 'error' });
      this.setState({ loading: false });
      return;
    }

    const stripe = window.Stripe('pk_test_WKOL8G3mj0zNobWOH22xlwjw00hInTTWDF');
    stripe
      .redirectToCheckout({
        sessionId,
      })
      .then(result => {
        console.log(result);
      });
  };

  getHandler(name) {
    return e => this.setState({ [name]: e.target.value });
  }

  render() {
    return (
      <DashboardContainer title="Sign up">
        <div className="subscribe">
          {this.state.loading && (
            <div className="loading">
              <CircularProgress size={70} />
            </div>
          )}
          <div>
            <h2>Subscribe to Jottle &ndash; 60-day free trial!</h2>
            <p>
              Subscribe to Jottle today and get unlimited saved files. You get a
              60-day free trial. After that, it is only £3 per month. And we
              have loads of exciting features in development: custom covers,
              templates and a liturgical library.
            </p>
            <form onSubmit={this.activate}>
              <TextField
                value={this.state.name}
                label="Name"
                variant="outlined"
                onChange={this.getHandler('name')}
                fullWidth
              />
              <TextField
                value={this.state.email}
                label="Email address"
                variant="outlined"
                onChange={this.getHandler('email')}
                fullWidth
              />
              <TextField
                value={this.state.password}
                label="Password"
                variant="outlined"
                onChange={this.getHandler('password')}
                fullWidth
                type="password"
              />
              <TextField
                value={this.state.confirmPassword}
                label="Confirm password"
                variant="outlined"
                onChange={this.getHandler('confirmPassword')}
                fullWidth
                type="password"
              />
              <Button variant="contained" color="primary" type="submit">
                Sign up now!
              </Button>
            </form>
          </div>
        </div>
      </DashboardContainer>
    );
  }
}

export default withSnackbar(Subscribe);
