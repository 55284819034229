import React from 'react';

import Modal from './Modal';
import DialogueButton from './DialogueButton';

import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';

import './documentSettingsDialogue.css';

export default class DocumentSettingsDialogue extends React.Component {
  settingsDescription = {
    highlightColour: {
      description: 'Colour for rubrics, headings, etc.',
      type: 'colour',
      css: true,
      default: '#8B0000',
      getValue(event) {
        return event.target.value;
      },
    },
    fontFamily: {
      description: 'Font',
      type: 'options',
      options: {
        Serif: ['Crimson Text', 'EB Garamond', 'Gentium Basic', 'Spectral'],
        'Sans-serif': [
          'Alegreya Sans',
          'Cabin',
          'Gill Sans',
          'OpenDyslexic',
          'Quattrocento Sans',
          'Roboto',
        ],
      },
      getValue(event) {
        return event.target.value || this.options[0];
      },
      note(option) {
        if (option === 'Gill Sans') {
          return (
            <div className="optionNote">
              For copyright reasons, Gill Sans will only work if it is installed
              on your local computer. If you do not have it installed, it will
              be rendered as Alegreya Sans instead.
            </div>
          );
        } else {
          return null;
        }
      },
    },
    headingFontFamily: {
      description: 'Heading font',
      type: 'options',
      css: true,
      options: {
        Serif: ['Crimson Text', 'EB Garamond', 'Gentium Basic', 'Spectral'],
        'Sans-serif': [
          'Alegreya Sans',
          'Cabin',
          'Gill Sans',
          'OpenDyslexic',
          'Quattrocento Sans',
          'Roboto',
        ],
      },
      defaultsTo: 'fontFamily',
      getValue(event) {
        return event.target.value || this.options[0];
      },
    },
    fontSize: {
      description: 'Font size',
      type: 'options',
      css: true,
      options: ['12.5pt', '10pt', '12pt', '14pt'],
      getValue(event) {
        return event.target.value || this.options[0];
      },
    },
    theme: {
      description: 'Theme',
      type: 'options',
      css: false,
      options: ['Dix', 'Wesley', 'Gregory'],
      getValue(event) {
        return event.target.value || this.options[0];
      },
    },
    showCover: {
      description: 'Display cover?',
      type: 'boolean',
      getValue(event) {
        return event.target.checked;
      },
    },
    churchName: {
      description: 'Church name',
      type: 'text',
      placeholder: 'Church name (displayed on the front cover)',
      getValue(event) {
        return event.target.value;
      },
    },
    coverColour: {
      description: 'Feature colour for the cover',
      type: 'colour',
      css: true,
      default: '#8B0000',
      getValue(event) {
        return event.target.value;
      },
    },
    serviceTitle: {
      description: 'Service title',
      type: 'text',
      placeholder: 'Service title (e.g. ‘The Parish Eucharist’)',
      getValue(event) {
        return event.target.value;
      },
    },
    serviceSubTitle: {
      description: 'Service subtitle',
      type: 'text',
      placeholder: 'Service subtitle (e.g. ‘in Ordinary Time’)',
      getValue(event) {
        return event.target.value;
      },
    },
    date: {
      description: 'Service date',
      type: 'text',
      placeholder: 'Service date',
      getValue(event) {
        return event.target.value;
      },
    },
    time: {
      description: 'Service time',
      type: 'text',
      placeholder: 'Service time',
      getValue(event) {
        return event.target.value;
      },
    },
    strapline: {
      description: 'Strapline',
      type: 'text',
      placeholder: 'The parish/diocesan strapline, or any other content',
      getValue(event) {
        return event.target.value;
      },
    },
  };

  getChangeHandler = name => {
    return value => {
      this.setState({
        [name]: this.settingsDescription[name].getValue(value) || '',
      });
    };
  };

  save = () => {
    this.props.save(this.state);
    this.setState({});

    this.props.close();
  };

  constructor(props) {
    super(props);

    this.state = Object.entries(this.settingsDescription).reduce(
      (output, [name, setting]) => {
        output[name] = props.documentSettings[name] || setting.default;

        return output;
      },
      {},
    );
  }

  render() {
    const options = Object.entries(this.settingsDescription).reduce(
      (output, [name, setting], idx) => {
        if (setting.type === 'text') {
          output.push(
            <TextField
              key={`option-${name}`}
              label={setting.description}
              autoComplete="off"
              margin="normal"
              placeholder={setting.placeholder}
              value={this.state[name]}
              name={name}
              onKeyPress={e => e.key === 'Enter' && e.ctrlKey && this.save()}
              onChange={this.getChangeHandler(name)}
              fullWidth
              variant="outlined"
            />,
          );
        } else if (setting.type === 'boolean') {
          output.push(
            <FormControlLabel
              key={`option-${name}`}
              control={
                <Switch
                  checked={this.state[name]}
                  className="switch"
                  onChange={this.getChangeHandler(name)}
                  inputProps={{
                    name,
                  }}
                  color="primary"
                />
              }
              label={setting.description}
              style={{ display: 'block' }}
            />,
          );
        } else if (setting.type === 'colour') {
          output.push(
            <FormControlLabel
              key={`option-${name}`}
              control={
                <Input
                  type="color"
                  value={this.state[name]}
                  onChange={this.getChangeHandler(name)}
                  style={{ width: '40%', marginRight: 10 }}
                />
              }
              label={setting.description}
              style={{ display: 'block' }}
            />,
          );
        } else if (setting.type === 'options') {
          let options;

          if (Array.isArray(setting.options)) {
            options = setting.options.map(option => {
              return (
                <MenuItem value={option} key={`option-${option}`}>
                  {option}
                </MenuItem>
              );
            });
          } else {
            options = Object.entries(setting.options).reduce(
              (output, [groupName, options]) => {
                for (let option of options) {
                  output.push(
                    <MenuItem value={option} key={`option-${option}`}>
                      {option} ({groupName})
                    </MenuItem>,
                  );
                }
                return output;
              },
              [],
            );
          }

          output.push(
            <FormControl key={`setting-${name}`}>
              <InputLabel htmlFor={`setting-${name}`}>
                {setting.description}
              </InputLabel>
              <Select
                value={
                  this.state[name] ||
                  setting.options[0] ||
                  Object.values(setting.options)[0][0]
                }
                onChange={this.getChangeHandler(name)}
                inputProps={{ id: `setting-${name}` }}
              >
                {options}
              </Select>
            </FormControl>,
          );
        } else {
          output.push(`${setting.type} not recognised`);
        }

        return output;
      },
      [],
    );

    return (
      <Modal visible={this.props.open} title="Document settings">
        <div className="modal-summary">
          Alter settings that affect the whole document.
        </div>
        <div className="modal-options documentSettings">{options}</div>
        <div className="settingsButtons">
          <DialogueButton onClick={this.props.close}>Cancel</DialogueButton>
          <DialogueButton onClick={this.save}>Save</DialogueButton>
        </div>
      </Modal>
    );
  }
}
