import React from 'react';
import { Redirect } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import ShuffleIcon from '@material-ui/icons/Shuffle';
import FileCopyIcon from '@material-ui/icons/FileCopy';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import { JottleContext } from '../context/JottleContext';

import { withSnackbar } from 'notistack';

class DeleteButton extends React.Component {
  state = { clicked: false };

  delete = () => this.setState({ clicked: true });

  cancel = () => this.setState({ clicked: false });

  render() {
    return (
      <>
        <Button
          variant="outlined"
          color="secondary"
          size="small"
          onClick={this.delete}
        >
          <DeleteIcon />
          Delete
        </Button>
        <Dialog open={this.state.clicked} onClose={this.cancel}>
          <DialogTitle>Delete</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Delete the service ‘{this.props.name}’?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button color="secondary" onClick={this.cancel}>
              Cancel
            </Button>
            <Button
              color="primary"
              variant="contained"
              autoFocus
              onClick={this.props.delete}
            >
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}

class EditButton extends React.Component {
  state = { clicked: false };
  go = () => {
    this.props.setLoading();
    this.setState({ clicked: true });
  };
  render() {
    if (this.state.clicked) {
      return <Redirect to={`/services/${this.props.hash}/edit`} push />;
    }
    return (
      <Button
        variant="contained"
        color="primary"
        onClick={this.go}
        size="small"
      >
        <EditIcon />
        Edit
      </Button>
    );
  }
}

class NewButton extends React.Component {
  static contextType = JottleContext;

  state = { newService: false, create: false, error: false };

  go = () => {
    const name = this.state.name;
    if (!name) {
      this.setState({ error: true });
      return;
    }

    this.context.api
      .createService(name, {})
      .then(hash => {
        this.props.enqueueSnackbar(`‘${this.state.name}’ created`, {
          variant: 'success',
        });
        return hash;
      })
      .then(hash => this.setState({ newService: hash }));
  };

  create = () => {
    this.setState({ create: true });
  };

  changeName = e => {
    this.setState({ name: e.target.value });
  };

  cancel = () => {
    this.setState({ name: null, create: false });
  };

  render() {
    if (this.state.newService) {
      return <Redirect to={`/services/${this.state.newService}/edit`} push />;
    } else {
      return (
        <>
          <Dialog open={this.state.create} onClose={this.cancel}>
            <DialogTitle>Create service</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Choose a descriptive name for your service. It will not appear
                anywhere in your order of service: it is just to help you find
                it in the future.
              </DialogContentText>
              <TextField
                onChange={this.changeName}
                autoFocus
                error={this.state.error}
                label="Service name"
                fullWidth
                onKeyPress={e => e.key === 'Enter' && this.go()}
              />
            </DialogContent>
            <DialogActions>
              <Button color="secondary" onClick={this.cancel}>
                Cancel
              </Button>
              <Button color="primary" variant="contained" onClick={this.go}>
                Create service
              </Button>
            </DialogActions>
          </Dialog>
          <div className="bottom-control">
            <Button onClick={this.create} color="primary" variant="outlined">
              <AddIcon />
              New service
            </Button>
          </div>
        </>
      );
    }
  }
}
NewButton = withSnackbar(NewButton);

class LogoutButton extends React.Component {
  static contextType = JottleContext;
  state = {};

  logout = () => {
    this.context.api.logout();
    this.props.enqueueSnackbar('Logged out');
    this.setState({ loggedOut: true });
  };

  render() {
    if (this.state.loggedOut) {
      return <Redirect to="/" push />;
    }

    if (!this.context.api.isLoggedIn()) {
      return null;
    }

    return (
      <Button color="inherit" onClick={this.logout} className="logout">
        Log out
      </Button>
    );
  }
}
LogoutButton = withSnackbar(LogoutButton);

class RenameButton extends React.Component {
  static contextType = JottleContext;
  state = { open: false };

  activate = () => {
    this.setState({ open: true });
  };
  cancel = () => {
    this.setState({ open: false, error: false });
  };
  changeName = e => {
    this.setState({ name: e.target.value });
  };
  apply = async () => {
    if (!this.state.name) {
      this.setState({ error: true });
      return;
    }

    this.context.api
      .renameService(this.props.hash, this.state.name)
      .then(() => {
        this.setState({ error: false, open: false });
        this.props.enqueueSnackbar('Rename successful', { variant: 'success' });
        this.props.rename(this.state.name);
      })
      .catch(() => {
        this.props.enqueueSnackbar('Rename failed', { variant: 'error' });
      });
  };

  render() {
    return (
      <>
        <Button
          color="secondary"
          variant="outlined"
          className="rename"
          size="small"
          onClick={this.activate}
        >
          <ShuffleIcon />
          Rename
        </Button>
        <Dialog open={this.state.open} onClose={this.cancel}>
          <DialogTitle>Rename service</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Choose a new name for your service. The current name is
            </DialogContentText>
            <DialogContentText>
              <em>{this.props.name}</em>
            </DialogContentText>
            <TextField
              onChange={this.changeName}
              autoFocus
              error={this.state.error}
              label="Service name"
              defaultValue={this.props.name}
              fullWidth
              onKeyPress={e => e.key === 'Enter' && this.apply()}
            />
          </DialogContent>
          <DialogActions>
            <Button color="secondary" onClick={this.cancel}>
              Cancel
            </Button>
            <Button color="primary" variant="contained" onClick={this.apply}>
              Rename service
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}
RenameButton = withSnackbar(RenameButton);

class DuplicateButton extends React.Component {
  static contextType = JottleContext;

  state = {
    duplicating: false,
    name: this.props.name + ' (copy)',
  };

  activate = () => {
    this.setState({ duplicating: true });
  };

  deactivate = () => {
    this.setState({ duplicating: false });
  };

  apply = () => {
    if (!this.state.name.trim()) {
      this.setState({ error: true });
      return;
    }

    this.props.duplicate(this.state.name);
    this.reset();
  };

  changeName = e => {
    this.setState({
      name: e.target.value,
    });
  };

  reset = () => {
    this.setState({
      duplicating: false,
      error: false,
      name: this.props.name,
    });
  };

  render() {
    return (
      <>
        <Button color="secondary" variant="outlined" onClick={this.activate}>
          <FileCopyIcon />
          Duplicate
        </Button>
        <Dialog open={this.state.duplicating} onClose={this.deactivate}>
          <DialogTitle>Duplicate service</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Choose a name for the duplicate service
            </DialogContentText>
            <TextField
              onChange={this.changeName}
              autoFocus
              error={this.state.error}
              label="Service name"
              value={this.state.name}
              fullWidth
              onKeyPress={e => e.key === 'Enter' && this.apply()}
            />
          </DialogContent>
          <DialogActions>
            <Button color="secondary" onClick={this.deactivate}>
              Cancel
            </Button>
            <Button color="primary" variant="contained" onClick={this.apply}>
              Create duplicate
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}

export {
  NewButton,
  RenameButton,
  DeleteButton,
  EditButton,
  DuplicateButton,
  LogoutButton,
};
