import React from 'react';
import CookieConsent from 'react-cookie-consent';

import EditLiturgy from './EditLiturgy';
import Dashboard from './dashboard/Dashboard';
import Login from './Login';
import Subscribe from './user/Subscribe';
import UserInfo from './user/UserInfo';

import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';

import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#5a86a8',
    },
    secondary: {
      main: '#ad1457',
    },
  },
  overrides: {
    MuiTableCell: {
      root: {
        paddingRight: 10,
        paddingLeft: 2,
      },
    },
    MuiButton: {
      root: {
        marginRight: 3,
      },
    },
    MuiCircularProgress: {
      root: {
        margin: '0 auto',
        display: 'block',
        marginTop: 15,
      },
    },
    MuiFormControl: {
      root: {
        display: 'block',
        marginTop: 15,
      },
    },
    MuiFormControlLabel: {
      root: {
        marginTop: 15,
        marginLeft: 0,
      },
    },
    MuiContainer: {
      maxWidthLg: {
        padding: 10,
        overflow: 'auto',
        position: 'relative',
      },
    },
    MuiPaper: {
      root: {
        padding: 10,
      },
    },
    MuiAppBar: {
      root: {
        padding: 0,
      },
    },
  },
});

export default function App(props) {
  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider
        maxSnack={1}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
      >
        <div id="app" className="grid-container">
          <Router>
            <div className="title">
              <Link to="/">
                jottle <em>beautiful liturgy for everyone</em>
              </Link>
            </div>
            <CookieConsent
              style={{ fontFamily: 'var(--system-font)' }}
              buttonStyle={{ fontFamily: 'var(--system-font)' }}
            >
              Jottle stores some data on your computer to enhance your
              experience.
            </CookieConsent>
            <Switch>
              <Route
                path="/"
                exact
                render={renderProps => {
                  return (
                    <div>
                      Welcome to Jottle, the future of liturgical typesetting.
                      You can either <Link to="/login">login</Link>{' '}
                      (pre-approved beta-testers only) or{' '}
                      <Link to="/services/test">try Jottle out now</Link>!
                    </div>
                  );
                }}
              />
              <Route path="/dashboard" exact component={Dashboard} />
              <Route path="/login" exact component={Login} />
              <Route
                path="/services/test"
                render={routeProps => {
                  return <EditLiturgy test ajax="/StJohn.json" />;
                }}
              />
              <Route
                path="/services/new"
                render={() => {
                  return <EditLiturgy new />;
                }}
              />

              <Route
                path="/services/:hash/edit"
                render={routeProps => {
                  return <EditLiturgy hash={routeProps.match.params.hash} />;
                }}
              />

              <Route
                path="/subscribe"
                render={routeProps => {
                  return <Subscribe />;
                }}
              />

              <Route path="/user" component={UserInfo} />
            </Switch>
          </Router>
        </div>
      </SnackbarProvider>
    </ThemeProvider>
  );
}
